@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap);

#home {
  background: radial-gradient(transparent, rgba(0, 0, 0, 0.33)),
    url(/src/resources/images/northernLights.jpeg) no-repeat center center fixed !important;
  background-size: cover !important;
  height: 100vh !important;
}

.scrollee {
  scroll-snap-align: start;
}
.ant-layout {
  background: none;
}
.hero-text-header {
  animation: 3s;
  animation-name: header;
}
.hero-text-subheader {
  animation: 3s;
  animation-name: subHeader;
}
.project-showcase-background {
  background-color: rgb(11, 37, 34) !important;
  background-size: cover !important;
  height: 100vh !important;
}
.scroller {
  flex-basis: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
}

@keyframes header {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  30% {
    transform: translate3d(0, -100%, 0);
  }
  60% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes subHeader {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  50% {
    transform: translate3d(0, -100%, 0);
  }
  80% {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 576px) {
  .hero-text-header {
    font-size: 9vw;
  }
  .hero-text-subheader {
    font-size: 3.66vw;
  }
}
@media (min-width: 576px) {
  .hero-text-header {
    font-size: 6vw;
  }
  .hero-text-subheader {
    font-size: 2.66vw;
  }
}
@media (min-width: 768px) {
  .hero-text-header {
    font-size: 4vw;
  }
  .hero-text-subheader {
    font-size: 2vw;
  }
}
@media (min-width: 992px) {
  .hero-text-header {
    font-size: 3.33vw;
  }
  .hero-text-subheader {
    font-size: 1.66vw;
  }
}
@media (min-width: 1200px) {
  .hero-text-header {
    font-size: 3vw;
  }
  .hero-text-subheader {
    font-size: 1.33vw;
  }
}
