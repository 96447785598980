@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap);
.hero-sider {
  align-items: center;
  -webkit-animation: 2s siderFadeIn;
          animation: 2s siderFadeIn;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  background: none;
  bottom: 0;
  display: flex;
  flex-direction: column;
  min-width: 8vw !important;
  max-width: 8vw !important;
  position: fixed;
  z-index: 2;
  width: 8vw !important;
  visibility: hidden;
}

.hero-sider::after {
  content: "";
  display: block;
  width: 1px;
  height: 90px;
  margin: 0px auto;
  background-color: white;
}

.icons {
  position: relative;
  margin: 16px auto;
}
.sider-icon {
  color: #fff;
  display: block;
  font-size: 1.25vw;
  margin: 24px auto;
}

.sider-text {
  position: relative;
  color: white;
  margin: 16px auto;
  font-size: 1rem;
  letter-spacing: 0.1em;
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
}

@media (min-width: 992px) {
  @-webkit-keyframes siderFadeIn {
    0% {
      visibility: visible;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
  @keyframes siderFadeIn {
    0% {
      visibility: visible;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
}
.opacity-layer {
    background: rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
}
.opacity-layer:hover {
    background: rgba(0, 0, 0, 0)
}
.container {
  position: absolute;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  bottom: 0;
  left: 50%;
}
.scroll {
  position: relative;
  margin: auto;
  text-align: center;
  height: 8vh;
  overflow: hidden;
}
.scroll__line {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1px;
  height: 100%;
  background-color: #fff;
  -webkit-animation: line 2s infinite normal;
          animation: line 2s infinite normal;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  visibility: hidden;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.scroll__arrow {
  display: block;
  position: relative;
  padding-left: 1px;
  top: -13px;
  -webkit-animation: 2s fadeIn;
          animation: 2s fadeIn;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  visibility: hidden;
}

@-webkit-keyframes line {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  5% {
    visibility: visible;
    opacity: 0.3;
    -webkit-transform: translate3d(0, -95%, 0);
            transform: translate3d(0, -95%, 0);
  }
  10% {
    opacity: 0.4;
    -webkit-transform: translate3d(0, -90%, 0);
            transform: translate3d(0, -90%, 0);
  }
  15% {
    opacity: 0.5;
    -webkit-transform: translate3d(0, -85%, 0);
            transform: translate3d(0, -85%, 0);
  }
  20% {
    opacity: 0.75;
  }
  25% {
    opacity: 1;
  }
  85% {
    -webkit-transform: translate3d(0, 95%, 0);
            transform: translate3d(0, 95%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
}

@keyframes line {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  5% {
    visibility: visible;
    opacity: 0.3;
    -webkit-transform: translate3d(0, -95%, 0);
            transform: translate3d(0, -95%, 0);
  }
  10% {
    opacity: 0.4;
    -webkit-transform: translate3d(0, -90%, 0);
            transform: translate3d(0, -90%, 0);
  }
  15% {
    opacity: 0.5;
    -webkit-transform: translate3d(0, -85%, 0);
            transform: translate3d(0, -85%, 0);
  }
  20% {
    opacity: 0.75;
  }
  25% {
    opacity: 1;
  }
  85% {
    -webkit-transform: translate3d(0, 95%, 0);
            transform: translate3d(0, 95%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  10% {
    visibility: visible;
    opacity: 0.3;
  }
  20% {
    opacity: 0.6;
  }
  30% {
    opacity: 1;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  10% {
    visibility: visible;
    opacity: 0.3;
  }
  20% {
    opacity: 0.6;
  }
  30% {
    opacity: 1;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .scroll {
    height: 9vh;
  }
}
@media (min-width: 1200px) {
  .scroll {
    height: 10vh;
  }
}

#home {
  background: radial-gradient(transparent, rgba(0, 0, 0, 0.33)),
    url(/static/media/northernLights.dd10862b.jpeg) no-repeat center center fixed !important;
  background-size: cover !important;
  height: 100vh !important;
}

.scrollee {
  scroll-snap-align: start;
}
.ant-layout {
  background: none;
}
.hero-text-header {
  -webkit-animation: 3s;
          animation: 3s;
  -webkit-animation-name: header;
          animation-name: header;
}
.hero-text-subheader {
  -webkit-animation: 3s;
          animation: 3s;
  -webkit-animation-name: subHeader;
          animation-name: subHeader;
}
.project-showcase-background {
  background-color: rgb(11, 37, 34) !important;
  background-size: cover !important;
  height: 100vh !important;
}
.scroller {
  flex-basis: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
}

@-webkit-keyframes header {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  30% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  60% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes header {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  30% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  60% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes subHeader {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  80% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes subHeader {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  80% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 576px) {
  .hero-text-header {
    font-size: 9vw;
  }
  .hero-text-subheader {
    font-size: 3.66vw;
  }
}
@media (min-width: 576px) {
  .hero-text-header {
    font-size: 6vw;
  }
  .hero-text-subheader {
    font-size: 2.66vw;
  }
}
@media (min-width: 768px) {
  .hero-text-header {
    font-size: 4vw;
  }
  .hero-text-subheader {
    font-size: 2vw;
  }
}
@media (min-width: 992px) {
  .hero-text-header {
    font-size: 3.33vw;
  }
  .hero-text-subheader {
    font-size: 1.66vw;
  }
}
@media (min-width: 1200px) {
  .hero-text-header {
    font-size: 3vw;
  }
  .hero-text-subheader {
    font-size: 1.33vw;
  }
}

.montserrat {
  font-family: Montserrat;
}

.white {
  color: white;
}

.bold {
  font-weight: 700;
}

.mask {
  overflow: hidden;
  position: relative;
}

